<template>
  <div class="container my-4">
    <section class="header">
      <router-link :to="{ name: 'login' }" class="back-login">
        <i class="ri-arrow-left-line ri-mid mr-1"></i>
        Back to log in
      </router-link>
      <h4 class="text-black ssp-28">UPL 'Tracko' Manual Book</h4>
    </section>
    <section class="menu">
      <b-tabs pills v-model="tabIndex" class="manual">
        <b-tab
          v-for="manual in manuals"
          :key="manual.fileId"
          :title="manual.name"
        >
          <div class="my-3">
            <iframe
              :src="`https://drive.google.com/file/d/${manual.fileId}/preview`"
              width="100%"
              style="height: 80vh;"
            />
          </div>
        </b-tab>
      </b-tabs>
    </section>
    <section class="view mt-4"></section>
  </div>
</template>

<script>
export default {
  name: "manual",
  data() {
    return {
      tabIndex: 0,
      manuals: [
        { name: "Account", fileId: "115Uiblh6jQzjIk-g6Ss7lkT1sDCepNNj" },
        { name: "TSS", fileId: "1LmvrX9mbU1fOX92EPofsYvaiFeJyk7kS" },
        { name: "SP", fileId: "12KD8H0zVKt_l3g1BoqspjCTqTtMb5Wap" }
      ]
    };
  }
};
</script>

<style>
body {
  background-color: #fff;
}
.manual div ul .nav-link.active {
  /* background-color: #f5f6fa !important; */
  border-radius: 0;
}
.back-login {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  color: #757575;
}
</style>
